import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router";
import { QueryClient } from "@tanstack/react-query";
import AuthRoute from "./components/ProtectedRoutes/AuthRoute";
import GuestRoute from "./components/ProtectedRoutes/GuestRoute";
import FallbackError from "./components/Errors/FallbackError";
import PageLoader from "./components/PageLoader";

// Actions
import { productLoader } from "./pages/Product/actions/fetchProduct";
import { fetchCompanyInfo } from "./pages/Auth/SignUp/loader";
import { loader as landingLoader } from "./pages/Landing/actions";

// Layouts
import MainLayout from "./layouts/MainLayout";
import HeroLayout from "./layouts/HeroLayout";
import SignupLayout from "./layouts/SignupLayout";
import CreatorDashboardLayout from "./layouts/CreatorDashboardLayout";
import SettingsLayout from "./layouts/SettingsLayout";
import RootLayout from "./layouts/RootLayout";
import OrderLayout from "./layouts/OrderLayout";
import PerformanceLayout from "./layouts/PerformanceLayout";

const queryClient = new QueryClient();

// Pages
const ContentPage = lazy(
  () => import("./pages/CreatorDashboard/Performance/Content")
);
const TeamManagementPage = lazy(
  () => import("./pages/CreatorDashboard/Company/TeamManagement")
);
const OrderPage = lazy(() => import("./pages/CreatorDashboard/Order"));
const AudiencePage = lazy(
  () => import("./pages/CreatorDashboard/Performance/Audience")
);
const OverviewPage = lazy(
  () => import("./pages/CreatorDashboard/Performance/Overview")
);
const SearchPage = lazy(() => import("./pages/Search"));
const PayoutsPage = lazy(() => import("./pages/Payouts"));
const LandingPage = lazy(() => import("./pages/Landing"));
const SubscriptionPage = lazy(() => import("./pages/Subscription"));
const CartPage = lazy(() => import("./pages/Cart"));
const PurchaseHistoryPage = lazy(() => import("./pages/Cart/History"));
const LoginPage = lazy(() => import("./pages/Auth/Login"));
const SignUpPage = lazy(() => import("./pages/Auth/SignUp"));
const NotFoundPage = lazy(() => import("./pages/404"));
const FAQPage = lazy(() => import("./pages/FAQ"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicy"));
const TermsPage = lazy(() => import("./pages/Terms"));
const ContentGuidelinesPage = lazy(() => import("./pages/ContentGuidelines"));
const AboutUsPage = lazy(() => import("./pages/AboutUs"));
const InviteFriendsPage = lazy(() => import("./pages/InviteFriends"));
const ResetPasswordPage = lazy(() => import("./pages/Auth/ResetPassword"));
const BecomeCreatorPage = lazy(() => import("./pages/BecomeCreator"));
const UploadPage = lazy(() => import("./pages/Upload"));
const PurchasesPage = lazy(() => import("./pages/CreatorDashboard/Purchases"));
const ProductPage = lazy(() => import("./pages/Product"));
const SalesPage = lazy(
  () => import("./pages/CreatorDashboard/Performance/Sales")
);
const CreatedPage = lazy(() => import("./pages/CreatorDashboard/Created"));
const PersonalInfoPage = lazy(() => import("./pages/Settings/PersonalInfo"));
const PaymentInfoPage = lazy(() => import("./pages/Settings/PaymentInfo"));
const CollectionsPage = lazy(
  () => import("./pages/CreatorDashboard/Collections")
);
const OrderSuccessPage = lazy(() => import("./pages/Order/Success"));
const OrderCanceledPage = lazy(() => import("./pages/Order/Canceled"));
const KYCPage = lazy(() => import("./pages/KYC"));
const ProfilePage = lazy(() => import("./pages/Profile"));
const HelloWorldPage = lazy(() => import("./pages/HelloWorld"));
const CreativePayoutsPage = lazy(
  () => import("./pages/CreatorDashboard/Payouts")
);
const InsightsPage = lazy(
  () => import("./pages/CreatorDashboard/Performance/Insights")
);
const BulkUploadGuidelinesPage = lazy(
  () => import("./pages/BulkUploadGuidelines")
);
const AILicensePage = lazy(() => import("./pages/AILicense"));
const CompanyRegistrationPage = lazy(() => import("./pages/Company"));

const LazyLoadedRoute = ({ children }) => {
  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
};

const wrapWithLazyLoadedRoute = (element) => (
  <LazyLoadedRoute>{element}</LazyLoadedRoute>
);

const routes = [
  {
    path: "/",
    errorElement: <FallbackError />,
    element: <RootLayout />,
    hydrateFallbackElement: <PageLoader />,
    children: [
      {
        path: "/hello-world",
        element: wrapWithLazyLoadedRoute(<HelloWorldPage />),
      },
      {
        path: "/",
        element: <HeroLayout />,
        children: [
          {
            path: "/",
            element: wrapWithLazyLoadedRoute(<LandingPage />),
            loader: landingLoader(queryClient),
          },
          {
            path: "faq",
            element: wrapWithLazyLoadedRoute(<FAQPage />),
          },
          {
            path: "content-guidelines",
            element: wrapWithLazyLoadedRoute(<ContentGuidelinesPage />),
          },
        ],
      },
      {
        path: "user/:userId",
        element: wrapWithLazyLoadedRoute(<ProfilePage />),
      },
      {
        path: "/",
        element: <AuthRoute element={<OrderLayout />} />,
        children: [
          {
            path: "orderSuccess/:orderId",
            element: wrapWithLazyLoadedRoute(<OrderSuccessPage />),
          },
          {
            path: "orderCancel/:orderId",
            element: wrapWithLazyLoadedRoute(<OrderCanceledPage />),
          },
        ],
      },
      {
        path: "about-us",
        element: wrapWithLazyLoadedRoute(<AboutUsPage />),
      },
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "privacy-policy",
            element: wrapWithLazyLoadedRoute(<PrivacyPolicyPage />),
          },
          {
            path: "terms-of-use",
            element: wrapWithLazyLoadedRoute(<TermsPage />),
          },
        ],
      },
      {
        path: "search",
        element: wrapWithLazyLoadedRoute(<SearchPage />),
      },
      {
        path: "product/:productId",
        element: wrapWithLazyLoadedRoute(<ProductPage />),
        loader: productLoader(queryClient),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/plans",
        element: wrapWithLazyLoadedRoute(
          <AuthRoute element={<SubscriptionPage />} />
        ),
      },
      {
        path: "/bulk-upload-guidelines",
        element: wrapWithLazyLoadedRoute(<BulkUploadGuidelinesPage />),
      },
      {
        path: "/ai-license",
        element: wrapWithLazyLoadedRoute(<AILicensePage />),
      },
      {
        path: "/payouts",
        element: wrapWithLazyLoadedRoute(<PayoutsPage />),
      },
      {
        path: "/cart",
        children: [
          {
            path: "",
            index: true,
            element: wrapWithLazyLoadedRoute(
              <AuthRoute element={<CartPage />} />
            ),
          },
          {
            path: "history",
            element: wrapWithLazyLoadedRoute(
              <AuthRoute element={<PurchaseHistoryPage />} />
            ),
          },
        ],
      },
      {
        path: "/settings",
        element: wrapWithLazyLoadedRoute(
          <AuthRoute element={<SettingsLayout />} />
        ),
        children: [
          {
            index: true,
            element: wrapWithLazyLoadedRoute(<PersonalInfoPage />),
          },
          {
            path: "payment-info",
            element: wrapWithLazyLoadedRoute(<PaymentInfoPage />),
          },
        ],
      },
      {
        path: "/",
        element: wrapWithLazyLoadedRoute(<SignupLayout />),
        children: [
          {
            path: "sign-up",
            element: wrapWithLazyLoadedRoute(
              <GuestRoute element={<SignUpPage />} />
            ),
            loader: fetchCompanyInfo,
          },
        ],
      },
      {
        path: "/",
        element: wrapWithLazyLoadedRoute(<SignupLayout creatorPage={true} />),
        children: [
          {
            path: "become-creator",
            element: wrapWithLazyLoadedRoute(
              <AuthRoute element={<BecomeCreatorPage />} />
            ),
          },
        ],
      },
      {
        path: "/creator-dashboard/purchases/:orderId",
        element: wrapWithLazyLoadedRoute(<AuthRoute element={<OrderPage />} />),
      },
      {
        path: "/creator-dashboard",
        element: wrapWithLazyLoadedRoute(
          <AuthRoute element={<CreatorDashboardLayout />} />
        ),
        children: [
          {
            path: "purchases",
            element: wrapWithLazyLoadedRoute(<PurchasesPage />),
          },
          {
            path: "",
            element: wrapWithLazyLoadedRoute(
              <AuthRoute element={<CreatedPage />} creatorPage />
            ),
          },
          {
            path: "collections",
            element: wrapWithLazyLoadedRoute(<CollectionsPage />),
          },
          {
            path: "payouts",
            element: wrapWithLazyLoadedRoute(
              <AuthRoute element={<CreativePayoutsPage />} creatorPage />
            ),
          },
          {
            path: "team-management",
            element: wrapWithLazyLoadedRoute(
              <AuthRoute element={<TeamManagementPage />} companyAdminPage />
            ),
          },
          {
            path: "performance",
            element: wrapWithLazyLoadedRoute(
              <AuthRoute element={<PerformanceLayout />} creatorPage />
            ),
            children: [
              {
                path: "",
                index: true,
                element: wrapWithLazyLoadedRoute(<OverviewPage />),
              },
              {
                path: "audience",
                element: wrapWithLazyLoadedRoute(<AudiencePage />),
              },
              {
                path: "content",
                element: wrapWithLazyLoadedRoute(<ContentPage />),
              },
              {
                path: "sales",
                element: wrapWithLazyLoadedRoute(<SalesPage />),
              },
              {
                path: "insights",
                element: wrapWithLazyLoadedRoute(<InsightsPage />),
              },
            ],
          },
        ],
      },
      {
        path: "sign-in",
        element: wrapWithLazyLoadedRoute(
          <GuestRoute element={<LoginPage />} />
        ),
      },
      {
        path: "company-info",
        element: wrapWithLazyLoadedRoute(
          <AuthRoute element={<CompanyRegistrationPage />} companyAdminPage />
        ),
      },
      {
        path: "reset-password",
        element: wrapWithLazyLoadedRoute(
          <GuestRoute element={<ResetPasswordPage />} />
        ),
      },
      {
        path: "invite-friends",
        element: wrapWithLazyLoadedRoute(
          <AuthRoute element={<InviteFriendsPage />} />
        ),
      },
      {
        path: "upload",
        element: wrapWithLazyLoadedRoute(
          <AuthRoute element={<UploadPage />} creatorPage kycOnly />
        ),
      },
      {
        path: "verification",
        element: wrapWithLazyLoadedRoute(<AuthRoute element={<KYCPage />} />),
      },
      {
        path: "*",
        element: wrapWithLazyLoadedRoute(<NotFoundPage />),
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
