import { cn } from "@/utils/utils";
import { IconType } from "react-icons";

interface SocialMediaLinkProps {
  icon: IconType;
  href: string;
  children: React.ReactNode;
  smallIcon?: boolean;
}

const SocialMediaLink = ({
  icon: SocialMediaIcon,
  href,
  children,
  smallIcon,
}: SocialMediaLinkProps) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="unstyled-link flex items-center gap-2 group hover:no-underline"
    >
      <SocialMediaIcon
        className={cn(
          "shrink-0",
          smallIcon ? "size-[18px] dark:text-[#A2A2A2]" : "size-6"
        )}
      />
      <span className="text-tertiary-main text-xs group-hover:text-primary-main dark:group-hover:text-accent-main">
        {children}
      </span>
    </a>
  );
};

export default SocialMediaLink;
