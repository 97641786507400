import { useTranslation } from "react-i18next";

// Components
import FooterMenu from "./FooterMenu";
import Logo from "@/components/Logo";
import SubfooterMenu from "./SubfooterMenu";

interface FooterProps {
  className?: string;
}

const Footer = ({ className = "" }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <footer
      className={`space-y-6 pt-20 pb-10 container max-w-full ${className}`}
    >
      <section className="flex max-xl:flex-col gap-10">
        <div className="w-full xl:w-1/3 xl:pr-28">
          <Logo size={51} beta={false} />
          <p className="text-tertiary-main mt-4 text-sm">{t("footer.about")}</p>
        </div>

        <FooterMenu />
      </section>

      <section className="pt-6 border-t dark:border-gray-800 flex max-sm:flex-wrap items-center justify-center gap-4 sm:gap-10">
        <SubfooterMenu smallText={false} />
      </section>
    </footer>
  );
};

export default Footer;
