import { useEffect, useRef, useState } from "react";
import { cn } from "@/utils/utils";
import { Outlet, useLocation } from "react-router";
import Footer from "@/components/Footer";
import Header from "@/components/Header";

const HeroLayout = () => {
  const heroRef = useRef<HTMLDivElement>(null);
  const sentinelRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();
  const [isPastHero, setIsPastHero] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: 0,
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    const ref = sentinelRef;
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (heroRef.current) {
        const heroHeight = heroRef.current.offsetHeight;
        setIsPastHero(window.scrollY >= heroHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div ref={sentinelRef} className="h-0"></div>
      <Header
        className={cn(
          "fixed top-0 left-0 z-40 dark:shadow-none h-[80px] bg-transparent transition-all duration-200",
          {
            "backdrop-blur-[11px]": isSticky,
          }
        )}
        searchBar={isPastHero && pathname === "/"}
        invertedColors={!isSticky}
        invertLoginColor={!isSticky}
        invertLogo={!isSticky}
        footerLinks={pathname === "/"}
        hideCurrencyPicker={pathname === "/"}
      />
      <Outlet context={[heroRef]} />
      {pathname !== "/" && <Footer />}
    </>
  );
};

export default HeroLayout;
