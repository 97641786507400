import { useRequest } from "@/hooks/useRequest";
import DownloadIcon from "@/assets/icons/download.svg?react";
import Button from "@/components/Button";
import toast from "react-hot-toast";

interface DownloadInvoiceButtonProps {
  orderId?: string;
}

const DownloadInvoiceButton = ({ orderId }: DownloadInvoiceButtonProps) => {
  const { sendRequest, loading } = useRequest();

  if (!orderId) return <></>;

  const handleDownload = async () => {
    try {
      const res = await sendRequest({
        method: "GET",
        url: `Orders/GetOrderById:${orderId}?responseType=pdf`,
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      toast.error(
        "Sorry, something went wrong and the invoice could not be downloaded. Please try again later, or contact us if this issue persists."
      );
      console.error(error);
    }
  };

  return (
    <Button
      variant="link"
      iconEnd={DownloadIcon}
      iconClassName="size-[16px]"
      className="!gap-2"
      loading={loading}
      onClick={() => handleDownload()}
      loaderClassName="size-4"
    >
      Download invoice
    </Button>
  );
};

export default DownloadInvoiceButton;
