import { useState, useCallback, useRef } from "react";
import { useAppSelector } from "@/redux/hooks";
import { userSelector } from "@/redux/App";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

type RequestParams = AxiosRequestConfig & {
  external?: boolean;
};

export const useRequest = <T = any>() => {
  const user = useAppSelector((state) => userSelector(state));
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [loading, setLoading] = useState(false);

  const sendRequest = useCallback(
    async <R = T>(params: RequestParams): Promise<AxiosResponse<R>> => {
      setLoading(true);
      try {
        const { url, external = false, ...axiosConfig } = params;

        const response = await axios({
          ...axiosConfig,
          signal: abortControllerRef.current.signal,
          url: external
            ? url
            : (import.meta.env.VITE_BACKEND_URL ?? "http://localhost:3000") +
              import.meta.env.VITE_API_POSTFIX +
              url,
          headers: {
            Authorization:
              user.token && !external ? `Bearer ${user.token}` : undefined,
            ...axiosConfig.headers,
          },
        });
        setLoading(false);
        return response;
      } catch (error) {
        setLoading(false);
        if (axios.isAxiosError(error)) {
          const errorResponse = error.response;
          throw errorResponse ? errorResponse.data : error.message;
        } else {
          throw error;
        }
      }
    },
    [user.token]
  );

  return { loading, abortControllerRef, sendRequest };
};
