import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { cn } from "@/utils/utils";
import Menu from "./Menu";

import CloseIcon from "@/assets/icons/times.svg?react";
import HamburgerIcon from "@/assets/icons/hamburgers/hamburger1.svg?react";

interface MobileMenuProps {
  invertedColors?: boolean;
}

const MobileMenu = ({ invertedColors = false }: MobileMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <button
        className="md:hidden shrink-0"
        aria-label="Toggle Mobile Menu"
        onClick={() => setIsMenuOpen(true)}
      >
        <HamburgerIcon
          className={cn(
            invertedColors ? "text-white" : "text-black dark:text-white"
          )}
        />
      </button>
      <Dialog
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        className="relative z-50"
      >
        <div className="fixed top-0 right-0 flex w-screen items-start justify-end">
          <button onClick={() => setIsMenuOpen(false)}>
            <CloseIcon className="text-tertiary mx-[7px] mt-[27px] size-[20px]" />
          </button>
          <DialogPanel className="w-full space-y-4 bg-white dark:bg-tertiary-950 pt-12 pb-20 h-screen shadow-lg max-w-[326px] flex flex-col justify-between overflow-y-auto">
            <Menu />
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default MobileMenu;
