import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { urlHasPrefix } from "@/utils/helpers";
import CircleLoader from "../Loaders/CircleLoader";

import { MdOutlineReportGmailerrorred } from "react-icons/md";
import WaveIcon from "@/assets/icons/media/bitrate_large.svg?react";

interface CartImageProps {
  title: string;
  src: string;
  className?: string;
  external?: boolean;
  isAudio?: boolean;
}

const CartImage = ({
  title,
  src,
  className,
  external,
  isAudio,
}: CartImageProps) => {
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current && src) {
      imgRef.current.src =
        external || urlHasPrefix(src)
          ? src
          : `${import.meta.env.VITE_THUMBNAIL_HOST}/${src}`;
      imgRef.current.onload = () => {
        setLoading(false);
      };
      imgRef.current.onerror = () => {
        setLoadError(true);
        setLoading(false);
      };
    }
  }, [src, external]);

  return (
    <div
      className={twMerge(
        "shrink-0 w-[100px] h-[69px] rounded-lg relative overflow-hidden",
        className
      )}
    >
      {(loading || loadError) && (
        <>
          <div className="absolute size-full inset-0 bg-tertiary-50 dark:bg-tertiary-900 flex items-center justify-center">
            {loading && <CircleLoader progress={50} className="size-12" />}
            {loadError && !isAudio && (
              <MdOutlineReportGmailerrorred
                className="text-red-500"
                size={32}
              />
            )}
            {(loadError || !src) && isAudio && (
              <WaveIcon className="text-accent-blue dark:text-accent-main" />
            )}
          </div>
          {loading && (
            <motion.div
              className="absolute size-full inset-0 bg-gradient-to-r from-transparent via-white/80 dark:via-tertiary-500/50 to-transparent"
              animate={{ x: ["-100%", "100%"] }}
              transition={{
                type: "tween",
                ease: "linear",
                duration: 3,
                repeat: Infinity,
              }}
            ></motion.div>
          )}
        </>
      )}
      <img ref={imgRef} alt={title} className="object-cover size-full" />
    </div>
  );
};

export default CartImage;
