import { cn } from "@/utils/utils";
import {
  Popover as HUIPopover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { AnchorProps } from "@headlessui/react/dist/internal/floating";
import { AnimatePresence, motion } from "framer-motion";

interface PopoverProps {
  button:
    | React.ReactNode
    | string
    | ((props: { open: boolean }) => React.ReactNode);
  className?: string;
  popoverClassName?: string;
  buttonClassName?: string;
  children: React.ReactNode;
  buttonOnClick?: () => void;
  anchor?: AnchorProps;
}

const Popover = ({
  button,
  className = "",
  popoverClassName = "",
  buttonClassName = "",
  children,
  buttonOnClick,
  anchor = "bottom end",
}: PopoverProps) => {
  return (
    <HUIPopover
      className={cn(
        "relative flex items-center justify-center",
        popoverClassName
      )}
    >
      {({ open }) => (
        <>
          <PopoverButton
            as={typeof button === "string" ? "button" : "div"}
            className={buttonClassName}
            onClick={buttonOnClick}
          >
            {typeof button === "function" ? button({ open }) : button}
          </PopoverButton>
          <AnimatePresence>
            {open && (
              <PopoverPanel
                anchor={anchor}
                static
                as={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className={cn(
                  "p-5 z-40 bg-white dark:bg-[#232323] rounded-xl shadow-[0px_4px_20px_0px_#A4A2A219] dark:shadow-default-dark origin-top",
                  className
                )}
              >
                {children}
              </PopoverPanel>
            )}
          </AnimatePresence>
        </>
      )}
    </HUIPopover>
  );
};

export default Popover;
