import { useState } from "react";
import { cn } from "@/utils/utils";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router";

import ChevronIcon from "@/assets/icons/chevron_down.svg?react";

interface MobileFooterLinkProps {
  links: {
    href: string;
    label: string;
  }[];
  children: React.ReactNode;
}

const MobileFooterLink = ({ links, children }: MobileFooterLinkProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        className="text-sm font-medium flex items-center justify-between w-full ps-[52px] pe-[62px]"
        onClick={() => setIsOpen(!isOpen)}
      >
        {children}
        <ChevronIcon
          className={cn(
            "text-tertiary transition-transform duration-200 size-[16px]",
            {
              "-rotate-180": isOpen,
            }
          )}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-tertiary-50 dark:bg-[#232323] py-[17px] ps-[53px] origin-top flex flex-col gap-[8px]"
          >
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.href}
                className="text-xs text-tertiary dark:text-[#A4A4A4] unstyled-link leading-[18px]"
              >
                {link.label}
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileFooterLink;
