export type TechnicalSpecsType = {
  $id: string | null;
  make: string | null;
  model: string | null;
  focalLength: string | null;
  flash: string | null;
  exposureProgram: string | null;
  format?: string | null;
  bitrate?: string | null;
  length?: string | null;
  type?: number | null;
} | null;

export type License = "Commercial" | "Editorial";

export enum Mood {
  "Humorous" = 1,
  "Serious" = 2,
  "Dramatic" = 3,
  "Neutral" = 4,
  "Angry" = 5,
  "Romantic" = 6,
  "Sad" = 7,
}

export enum AudioTechnicalType {
  "SoundEffects" = 0,
  "Jingle" = 1,
  "MusicWithVO" = 2,
}

export type Cast = {
  gender?: string;
  nationality: string;
  age?: AgeDemographic | string;
  language?: string;
};

export type Product = {
  $id: string;
  id: string;
  videoUrl?: string;
  thumbnailUrl?: string;
  trackUrl?: string;
  productId: string;
  name?: string;
  title: string;
  category: string | null;
  subCategory: string | null;
  description: string;
  technicalSpecs: TechnicalSpecsType;
  location: string;
  tags: string | null;
  fileUrl: string | null;
  fileKey?: string[];
  categories?: string[];
  rejectionReason: string | null;
  addedDate: string;
  status: number;
  favoriteCount: number;
  creatorName: string | null;
  creatorProfilePicture: string | null;
  creatorId: string | null;
  sku?: string | null;
  price?: number | null;
  isHidden?: boolean;
  images?: string[];
  videos?: string[];
  audios?: string[];
  mediaId?: string | null;
  isFavorite?: boolean;
  licenseType?: License;
  license?: string;
  productCasts: {
    $values: Cast[];
  };
  productPrices: {
    $values: {
      id: string;
      price: number;
      size: ProductSize;
    }[];
  };
  videoThumbnail?: string | null;
  type?: string;
  instruments: {
    $values: string[];
  };
  mood?: Mood;
  genres?: string[];
  thumbnail?: string;
  orderId?: string;
  orderNumber?: number;
  typeWithExtension?: string;
  size?: number;
  userName?: string | null;
  isAiGenerated?: boolean;
  height?: number;
  width?: number;
  aspectRatio?: number;
  videoType?: "Video" | "VerticalVideo";
};

export type ContentType = "image" | "video" | "audio";

export enum Resolution {
  "4K" = 3,
  "2K" = 2,
  "1080p" = 1,
}

export enum UploadType {
  Photo = 1,
  Video = 2,
  Audio = 3,
  AR = 4,
}

export enum ProductSize {
  Small = 1,
  Medium = 2,
  Large = 3,
}

export enum DeviceType {
  Phone = 1,
  Camera = 2,
  Drone = 3,
}

export enum CastAge {
  "18-25" = "Young",
  "25-40" = "Adult",
  "40-60" = "Middle-aged",
  "60+" = "Senior",
}

export enum AgeDemographic {
  "Adult" = "1",
  "Child" = "2",
  "Elderly" = "3",
}

export enum ProductStatus {
  Deleted = 0,
  Approved = 1,
  Hidden = 2,
  PendingApproval = 3,
  Rejected = 4,
}

export enum ProductType {
  Photo = "1",
  Video = "2",
  "Vertical Video" = "3",
  Audio = "4",
  AR = "5",
}

export type MinimizedAudio = {
  trackName?: string;
  trackUrl?: string;
  productId?: string;
  authorId?: string;
  authorName?: string;
  startAt?: number;
  thumbnail?: string;
};

export type SortOptions = "date" | "az" | "old" | "new";
