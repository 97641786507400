import { useState } from "react";
import { AnchorProps } from "@headlessui/react/dist/internal/floating";
import { AnimatePresence, motion } from "framer-motion";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { cn } from "@/utils/utils";

import { HiOutlineExclamationCircle } from "react-icons/hi";

interface HelpTooltipProps {
  label?: string;
  className?: string;
  container?: React.ReactNode;
  children: React.ReactNode;
  iconClassName?: string;
  anchor?: AnchorProps;
  arrow?: boolean;
}

const HelpTooltip = ({
  label,
  className,
  container,
  children,
  iconClassName,
  anchor = "right",
  arrow,
}: HelpTooltipProps) => {
  const [tooltip, showTooltip] = useState(false);

  return (
    <Popover
      as="div"
      className="relative group z-10 flex items-center justify-center"
      onMouseEnter={() => showTooltip(true)}
      onMouseLeave={() => showTooltip(false)}
      onFocus={() => showTooltip(true)}
      onBlur={() => showTooltip(false)}
    >
      <PopoverButton onClick={() => showTooltip(!tooltip)}>
        {container ? (
          container
        ) : (
          <HiOutlineExclamationCircle
            className={cn(
              "size-5 text-[#D0D1D2] dark:text-tertiary cursor-pointer",
              iconClassName
            )}
            tabIndex={0}
            aria-label={label}
          />
        )}
      </PopoverButton>

      <AnimatePresence>
        {tooltip && (
          <PopoverPanel
            static
            as={motion.div}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            anchor={anchor}
            onMouseEnter={() => showTooltip(true)}
            onMouseLeave={() => showTooltip(false)}
            className={cn(
              "text-sm bg-accent-blue dark:bg-accent-main text-white dark:text-tertiary-dark-gray rounded-xl sm:w-52 px-6 py-3 text-center absolute -top-2 start-[160%] [--anchor-gap:15px] !overflow-visible",
              {
                "before:w-0 before:h-0 before:absolute max-md:before:hidden":
                  arrow,
                "before:border-r-[10px] before:border-r-accent-blue dark:before:border-r-accent-main before:border-y-[10px] before:border-y-transparent":
                  String(anchor).includes("right") && arrow,
              },
              className
            )}
          >
            {children}
          </PopoverPanel>
        )}
      </AnimatePresence>
    </Popover>
  );
};

export default HelpTooltip;
