import { useTranslation } from "react-i18next";
import MenuLink from "./MenuLink";

interface SubfooterMenuProps {
  smallText?: boolean;
}

const SubfooterMenu = ({ smallText }: SubfooterMenuProps) => {
  const { t } = useTranslation();

  return (
    <>
      <MenuLink to="/privacy-policy" smallText={smallText}>
        {t("sub_footer.privacy")}
      </MenuLink>
      <MenuLink to="/content-guidelines" smallText={smallText}>
        {t("sub_footer.content_guidelines")}
      </MenuLink>
      <MenuLink to="/terms-of-use" smallText={smallText}>
        {t("sub_footer.terms")}
      </MenuLink>
    </>
  );
};

export default SubfooterMenu;
