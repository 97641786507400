import { useState, useRef, useEffect } from "react";
import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import { useNavigate, useSearchParams } from "react-router";
import { Category } from "@/shared/types/categories";
import { Popover, PopoverPanel, Transition } from "@headlessui/react";
import { cn } from "@/utils/utils";
import Input from "@/components/Input";
import ContentFilter from "@/components/Filters/ContentFilter";
import RecentSearches from "./components/RecentSearches";

import SearchIcon from "@/assets/icons/search.svg?react";

interface SearchProps {
  className?: string;
  inputClassName?: string;
  hideFilterOnMobile?: boolean;
  inHeader?: boolean;
}

const Search = ({
  className = "",
  inputClassName,
  hideFilterOnMobile = false,
  inHeader = false,
}: SearchProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") ?? "");
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const [category, setCategory] = useState<Category>();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim() === "" || searchTerm?.length < 3) return;
    navigate(
      `/search?q=${searchTerm}${
        category && category.id !== "all"
          ? `&type=${category.name?.toLowerCase()}`
          : ""
      }`
    );
  };

  const handleFocus = () => {
    if (!isLoggedIn) return;
    setIsOpen(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (
      relatedTarget &&
      (inputRef.current?.contains(relatedTarget) ||
        popoverRef.current?.contains(relatedTarget))
    ) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      if (
        (inputRef.current?.contains(event.target as Node) ||
          popoverRef.current?.contains(event.target as Node)) &&
        isLoggedIn
      ) {
        setIsOpen(true);
      }
    };

    const handleFocusOut = (event: FocusEvent) => {
      if (
        !inputRef.current?.contains(event.relatedTarget as Node) &&
        !popoverRef.current?.contains(event.relatedTarget as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);

    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, [isLoggedIn]);

  // Update search term when URL changes
  useEffect(() => {
    setSearchTerm(searchParams.get("q") ?? "");
  }, [searchParams]);

  return (
    <form className={cn("w-full", className)} onSubmit={handleSearchSubmit}>
      <Popover className="relative w-full">
        {({ open }) => (
          <>
            <div
              className={cn(
                "flex bg-white dark:bg-tertiary-dark-gray rounded-full group focus-within:ring-2 focus-within:ring-accent-blue dark:focus-within:ring-accent-main transition-all duration-200 w-full max-w-[620px] gap-[6.32px] md:gap-[14.25px] border-[1px] border-transparent dark:border-[#484747] box-border",
                {
                  "max-w-full shadow-default dark:shadow-none gap-[10px] md:gap-[12.64px]":
                    inHeader,
                  "max-h-[52px] md:max-h-[64px]": !inHeader,
                }
              )}
            >
              <div
                className={cn(
                  "flex items-center justify-center ps-[15.68px] md:ps-[20px]",
                  {
                    "ps-[16px] md:ps-[25px]": inHeader,
                  }
                )}
              >
                <SearchIcon
                  className={cn("size-[18px] md:size-[20px] text-[#969696]", {
                    "text-tertiary": inHeader,
                  })}
                />
              </div>
              <Input
                placeholder="Search..."
                className={cn(
                  "bg-white dark:bg-tertiary-dark-gray rounded-none placeholder:text-sm placeholder:text-[#969696] px-0 focus:ring-0 h-full",
                  {
                    "placeholder:text-tertiary font-medium text-tertiary max-md:rounded-e-full py-[11px]":
                      inHeader,
                  }
                )}
                containerClassName="flex-1 min-w-[auto]"
                ref={inputRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="search"
                autoComplete="off"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div
                className={cn(
                  "flex items-center justify-center py-[10px] md:py-[9px] pe-[10px] md:pe-[12px]",
                  {
                    "md:pe-[17px] md:py-[5.89px] max-md:hidden": inHeader,
                  }
                )}
              >
                <ContentFilter
                  buttonClassName="bg-gradient-to-r from-[#8A69CC] via-[#418FE8] to-black dark:from-accent-main dark:to-accent-main text-white dark:text-black after:opacity-40 px-[18px] md:ps-[31px] md:pe-[29px] py-[8.17px] md:py-[11px] rounded-full after:content-none"
                  chevronClassName="size-[15.65px] md:size-[24px]"
                  selectedOption={category}
                  setSelectedOption={setCategory}
                />
              </div>
            </div>
            <Transition
              show={isOpen}
              enter="duration-200 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="duration-300 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <PopoverPanel
                ref={popoverRef}
                className="absolute z-40 max-[420px]:left-1/2 max-[420px]:-translate-x-1/2 max-[420px]:w-screen w-full px-7 py-8 bg-white dark:bg-tertiary-950 rounded-xl shadow-xl transition translate-y-2 md:translate-y-4 space-y-8"
              >
                <RecentSearches />
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </form>
  );
};

export default Search;
