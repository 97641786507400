import { cn } from "@/utils/utils";
import config from "@/config.json";
import SocialMediaLink from "./SocialMediaLink";
import MenuLink from "./MenuLink";

import { FaInstagram, FaTiktok } from "react-icons/fa6";

interface FooterMenuProps {
  className?: string;
  smallIcons?: boolean;
}

export const footerLinks = {
  company: [
    { href: "/about-us", label: "About Us" },
    { href: "/privacy-policy", label: "Privacy Policy" },
    { href: "/payouts", label: "Payouts" },
    { href: "/ai-license", label: "AI License" },
  ],
  help: [
    { href: "/faq", label: "FAQs" },
    {
      href: `mailto:${config.support.email}`,
      label: "Contact Us",
    },
    { href: config.support.helpdesk, label: "Customer Support" },
  ],
  careers: [{ href: "/become-creator", label: "Join the Creator Community" }],
};

const FooterMenu = ({ className, smallIcons }: FooterMenuProps) => {
  return (
    <div
      className={cn(
        "grid grid-cols-2 md:grid-cols-4 gap-10 md:gap-16 xl:gap-40 w-full",
        className
      )}
    >
      <div className="space-y-4">
        <p className="text-sm font-medium">Company</p>
        <ul className="flex flex-col gap-2">
          {footerLinks.company.map((link) => (
            <li key={link.href}>
              <MenuLink to={link.href}>{link.label}</MenuLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="space-y-4">
        <p className="text-sm font-medium">Help Center</p>
        <ul className="flex flex-col gap-2">
          {footerLinks.help.map((link) => (
            <li key={link.href}>
              <MenuLink to={link.href}>{link.label}</MenuLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="space-y-4">
        <p className="text-sm font-medium">Careers</p>
        <ul className="flex flex-col gap-2">
          {footerLinks.careers.map((link) => (
            <li key={link.href}>
              <MenuLink to={link.href}>{link.label}</MenuLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="space-y-4">
        <p className="text-sm font-medium">Community</p>
        <ul className={cn("flex flex-col gap-4", smallIcons && "gap-[12px]")}>
          <li>
            <SocialMediaLink
              icon={FaInstagram}
              href={config.socialmedia.instagram}
              smallIcon={smallIcons}
            >
              Instagram
            </SocialMediaLink>
          </li>
          <li>
            <SocialMediaLink
              icon={FaTiktok}
              href={config.socialmedia.tiktok}
              smallIcon={smallIcons}
            >
              TikTok
            </SocialMediaLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterMenu;
