import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useRequest } from "@/hooks/useRequest";
import { useQuery } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Currency } from "@/shared/types/currencies";
import { cn } from "@/utils/utils";
import Input from "@/components/Input";
import CountryItem from "./CountryItem";
import CircleLoader from "@/components/Loaders/CircleLoader";
import { currencySelector, setCurrency } from "@/redux/App";
import toast from "react-hot-toast";

interface FilteredPickerProps {
  wrapperClassName?: string;
  searchInputClassName?: string;
}

type CurrencyResponse = {
  data: {
    $values: Currency[];
  };
};

const FilteredPicker = ({
  wrapperClassName,
  searchInputClassName,
}: FilteredPickerProps) => {
  const dispatch = useAppDispatch();
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(
    useAppSelector(currencySelector)
  );
  const { sendRequest, loading } = useRequest();
  const fetchCurrencies = async () => {
    try {
      const res = await sendRequest({
        method: "GET",
        url: "Currency/getAllCurrencies",
      });
      const data: CurrencyResponse = await res.data;
      return data?.data?.$values?.filter(
        (currency) => currency.currency !== "ILS"
      );
    } catch (error) {
      console.error(error);
    }
  };
  const { data, isLoading } = useQuery({
    queryFn: fetchCurrencies,
    queryKey: ["currencies"],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
  });

  const handleSelectCurrency = async (currency: Currency) => {
    try {
      await sendRequest({
        method: "POST",
        url: "Users/preferred-currency",
        data: {
          currency: currency.currency,
        },
      });
      setSelectedCurrency(currency);
      dispatch(setCurrency(currency));
    } catch (error) {
      toast.error(
        "Your currency could not be changed. Please try again later."
      );
    }
  };

  const filteredCurrencies =
    data?.filter(
      (currency) =>
        currency.currency.toLowerCase().includes(searchFilter.toLowerCase()) ||
        currency.countryName.toLowerCase().includes(searchFilter.toLowerCase())
    ) || [];

  const virtualizerRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: data?.length || 172,
    getScrollElement: () => virtualizerRef.current,
    estimateSize: () => 35,
  });

  return (
    <>
      <div className="!px-3">
        <Input
          placeholder="Search"
          type="text"
          className={cn("dark:!bg-[#333333]", searchInputClassName)}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>

      {isLoading && (
        <div className="pb-4">
          <CircleLoader className="size-14 mx-auto" progress={50} />
        </div>
      )}

      {filteredCurrencies?.length > 0 && (
        <div
          ref={virtualizerRef}
          style={{
            overflow: "auto",
          }}
        >
          <div
            className={cn(
              "space-y-4 custom-scrollbar h-full !overflow-y-auto pb-6",
              wrapperClassName
            )}
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            {filteredCurrencies.map((currency) => (
              <CountryItem
                key={currency.$id}
                country={currency.countryName}
                currency={currency.currency}
                countryCode={currency.iso}
                selected={selectedCurrency?.currency === currency.currency}
                onClick={() => handleSelectCurrency(currency)}
                disabled={loading}
              />
            ))}
          </div>
        </div>
      )}

      {filteredCurrencies.length === 0 && !isLoading && (
        <p className="text-sm text-tertiary text-center pb-4">
          No currencies found
        </p>
      )}
    </>
  );
};

export default FilteredPicker;
