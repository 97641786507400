import { cn } from "@/utils/utils";
import { Dialog, DialogPanel } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import { IoCloseOutline } from "react-icons/io5";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  closeIconClassName?: string;
  closeIconSize?: number;
  hideX?: boolean;
  contentOnly?: boolean;
}

const Modal = ({
  open = false,
  onClose,
  children,
  className = "",
  closeIconClassName = "",
  closeIconSize = 32,
  hideX = false,
  contentOnly,
  ...props
}: ModalProps) => {
  return (
    <AnimatePresence>
      {open && (
        <Dialog static open={open} onClose={onClose} className="relative z-50">
          <motion.div
            className={cn("fixed inset-0", {
              "bg-black/25": !contentOnly,
              "bg-black/80 dark:bg-tertiary-950/80": contentOnly,
            })}
            aria-hidden="true"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />

          <div className="fixed inset-0 flex w-screen items-center justify-center">
            <DialogPanel
              as={motion.div as any}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className={cn(
                "max-md:mx-[20px]",
                {
                  "w-full max-w-lg bg-white dark:bg-black p-6 sm:p-16 rounded-2xl shadow-2xl relative overflow-y-auto":
                    !contentOnly,
                },
                className
              )}
              {...props}
            >
              {!hideX && (
                <button
                  className={cn(
                    "absolute top-4 end-4 z-10",
                    {
                      "text-tertiary hover:text-tertiary-main": !contentOnly,
                      "text-white": contentOnly,
                    },
                    closeIconClassName
                  )}
                  onClick={onClose}
                >
                  <IoCloseOutline size={closeIconSize} />
                </button>
              )}
              {children}
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export default Modal;
