import Button from "@/components/Button";
import Popover from "@/components/Popover";
import FilteredPicker from "./FilteredPicker";
import { AnchorProps } from "@headlessui/react/dist/internal/floating";

import GlobeIcon from "@/assets/icons/misc/globe.svg?react";
import ChevronIcon from "@/assets/icons/chevron_down.svg?react";
import { cn } from "@/utils/utils";

interface CurrencyPickerProps {
  anchor?: AnchorProps;
  withText?: boolean;
}

const CurrencyPicker = ({
  anchor = "bottom end",
  withText,
}: CurrencyPickerProps) => {
  return (
    <Popover
      anchor={anchor}
      button={({ open }) =>
        withText ? (
          <button className="flex items-center gap-[8px] text-sm text-tertiary-main">
            <GlobeIcon className="size-[16px]" />
            Currency
            <ChevronIcon
              className={cn("size-[14px] transition-transform duration-200", {
                "-rotate-180": open,
              })}
            />
          </button>
        ) : (
          <Button variant="default-dimmed" tiny={true} icon={GlobeIcon} />
        )
      }
      className="[--anchor-gap:12px] w-[280px] space-y-6 !px-0 !pe-[6px] !pb-0 !overflow-y-hidden border-[1px] border-transparent dark:border-[#898989]/10"
    >
      <FilteredPicker wrapperClassName="max-h-[389px]" />
    </Popover>
  );
};

export default CurrencyPicker;
