import { AudioTechnicalType, Cast, DeviceType } from "@/shared/types/product";
import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ExifType = {
  Make?: string | null;
  Model?: string | null;
  FocalLength?: string | null;
  Flash?: string | null;
  ExposureProgram?: string | null;
};

type AudioType = {
  trackName?: string;
  file?: string;
  format?: string;
  bitrate?: number;
  duration?: number;
  type?: AudioTechnicalType;
};

export type UploadedContentType = "image" | "video" | "audio";

interface UploadState {
  exif: ExifType;
  type: UploadedContentType;
  imageUrl?: string | null;
  productId?: string | null;
  hasExifData?: boolean;
  thumbnail?: string | null;
  thumbnails?: string[];
  deviceType?: DeviceType;
  cast?: Cast[];
  size?: number;
  isVertical?: boolean;
  uploadType?: number;
  upload: {
    progress: number;
    error: string | null;
    isUploading: boolean;
    filename?: string;
    filesize?: number;
  };
  audio?: AudioType;
  contentMadeBy?: "human" | "ai";
}

const initialState: UploadState = {
  exif: {
    Make: null,
    Model: null,
    FocalLength: null,
    Flash: null,
    ExposureProgram: null,
  },
  type: "audio",
  imageUrl: null,
  productId: null,
  hasExifData: false,
  thumbnail: null,
  cast: [],
  upload: {
    error: null,
    progress: 0,
    isUploading: false,
  },
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    addCast: (state, action: PayloadAction<Cast>) => {
      state.cast?.push(action.payload);
    },
    updateCast: (
      state,
      action: PayloadAction<{ index: number; cast: Cast }>
    ) => {
      state.cast?.splice(action.payload.index, 1, action.payload.cast);
    },
    removeCast: (state, action: PayloadAction<number>) => {
      state.cast?.splice(action.payload, 1);
    },
    resetCast: (state) => {
      state.cast = [];
    },
    setExifData: (state, action: PayloadAction<ExifType>) => {
      state.exif = action.payload;
      state.hasExifData = true;
    },
    manualExifData: (
      state,
      action: PayloadAction<{ key: keyof ExifType; value: string | null }>
    ) => {
      state.exif[action.payload.key] = action.payload.value;
    },
    setFileInfo: (
      state,
      action: PayloadAction<{
        filename: string;
        size: number;
      }>
    ) => {
      state.upload.filename = action.payload.filename;
      state.upload.filesize = action.payload.size;
      state.upload.progress = 0;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setIsVertical: (state, action: PayloadAction<boolean>) => {
      state.isVertical = action.payload;
    },
    setUploadType: (state, action: PayloadAction<number>) => {
      state.uploadType = action.payload;
      state.deviceType = undefined;
      if (state.audio?.type) {
        state.audio.type = undefined;
      }
    },
    resetExifData: (state) => {
      state.exif = initialState.exif;
      state.hasExifData = false;
      state.deviceType = undefined;
      if (state.audio?.type) {
        state.audio.type = undefined;
      }
    },
    setThumbnail: (state, action) => {
      state.thumbnail = action.payload;
    },
    setThumbnails: (state, action: PayloadAction<string[]>) => {
      state.thumbnails = action.payload;
    },
    addThumbnail: (state, action: PayloadAction<string>) => {
      state.thumbnails?.push(action.payload);
    },
    setImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    setProductId: (state, action: PayloadAction<string>) => {
      state.productId = action.payload;
    },
    setType: (state, action: PayloadAction<UploadedContentType>) => {
      state.type = action.payload;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.upload.progress = action.payload;
    },
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.upload.isUploading = action.payload;
    },
    abortFileUpload: (state) => {
      state.upload.progress = 0;
      state.upload.isUploading = false;
      state.audio = undefined;
      state.contentMadeBy = undefined;
    },
    setDeviceType: (state, action: PayloadAction<DeviceType>) => {
      state.deviceType = action.payload;
    },
    setAudioInfo: (state, action: PayloadAction<AudioType>) => {
      state.audio = action.payload;
    },
    setAudioType: (state, action: PayloadAction<AudioTechnicalType>) => {
      state.audio = state.audio || {};
      state.audio.type = action.payload;
    },
    setContentMadeBy: (state, action: PayloadAction<"human" | "ai">) => {
      state.contentMadeBy = action.payload;
    },
  },
});

export const {
  setExifData,
  setThumbnail,
  setThumbnails,
  addThumbnail,
  setImageUrl,
  setProductId,
  resetExifData,
  setType,
  setProgress,
  setFileInfo,
  addCast,
  removeCast,
  updateCast,
  setIsUploading,
  abortFileUpload,
  setSize,
  setUploadType,
  setIsVertical,
  resetCast,
  manualExifData,
  setDeviceType,
  setAudioInfo,
  setAudioType,
  setContentMadeBy,
} = uploadSlice.actions;

export const hasExifSelector = (state: RootState) =>
  state.uploadSlice.hasExifData;
export const uploadStateSelector = (state: RootState) => state.uploadSlice;
export const exifSelector = (state: RootState) => state.uploadSlice.exif;
export const imageSelector = (state: RootState) => state.uploadSlice.imageUrl;
export const thumbnailSelector = (state: RootState) =>
  state.uploadSlice.thumbnail;
export const thumbnailsSelector = (state: RootState) =>
  state.uploadSlice.thumbnails;
export const productIdSelector = (state: RootState) =>
  state.uploadSlice.productId;
export const typeSelector = (state: RootState) => state.uploadSlice.type;
export const progressSelector = (state: RootState) =>
  state.uploadSlice.upload.progress;
export const castSelector = (state: RootState) => state.uploadSlice.cast;
export const sizeSelector = (state: RootState) => state.uploadSlice.size;
export const uploadTypeSelector = (state: RootState) =>
  state.uploadSlice.uploadType;
export const isVerticalSelector = (state: RootState) =>
  state.uploadSlice.isVertical;
export const deviceTypeSelector = (state: RootState) =>
  state.uploadSlice.deviceType;
export const audioSelector = (state: RootState) => state.uploadSlice.audio;
export const audioTypeSelector = (state: RootState) =>
  state.uploadSlice.audio?.type;
export const contentMadeBySelector = (state: RootState) =>
  state.uploadSlice.contentMadeBy;
export const isAiContent = (state: RootState) =>
  Boolean(state.uploadSlice.contentMadeBy === "ai");

export default uploadSlice.reducer;
