import { isLoggedInSelector } from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";
import Button from "@/components/Button";
import Popover from "@/components/Popover";
import FooterMenu from "@/components/Footer/FooterMenu";
import SubfooterMenu from "@/components/Footer/SubfooterMenu";
import Divider from "@/components/Divider";
import CurrencyPicker from "./CurrencyPicker";

import HamburgerIcon from "@/assets/icons/hamburgers/hamburger1.svg?react";
import { cn } from "@/utils/utils";

const FooterLinks = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  return (
    <Popover
      anchor="bottom end"
      button={
        <Button
          variant="default-dimmed"
          tiny
          className="flex flex-col items-center justify-center !gap-[8px] group !overflow-visible"
          icon={HamburgerIcon}
        />
      }
      className="[--anchor-gap:27px] w-full !max-w-[789px] rounded-[12px] !px-0 pt-[57px] pb-[25px] shadow-default dark:shadow-default-dark !overflow-visible relative before:size-0 before:absolute before:-top-[8px] before:end-[11px] before:border-x-transparent before:border-x-[6px] before:border-b-[8px] before:border-b-white dark:before:border-b-[#232323] !origin-top-right"
    >
      <FooterMenu className="!px-[50px] xl:!gap-[80px]" smallIcons />

      <Divider className="bg-tertiary-light-gray dark:bg-[#313131] mt-[75px] mb-[25px]" />

      <div
        className={cn("!px-[35px] flex items-center justify-between", {
          "justify-center": !isLoggedIn,
        })}
      >
        {isLoggedIn && <CurrencyPicker withText />}
        <div className="flex items-center gap-[40px]">
          <SubfooterMenu smallText={false} />
        </div>
      </div>
    </Popover>
  );
};

export default FooterLinks;
