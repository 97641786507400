import { Product } from "@/shared/types/product";
import { sendRequest } from "@/utils/sendRequest";

export const trendingProductsQuery = (hasProducts = true) => {
  return {
    queryKey: ["trending"],
    queryFn: async ({
      pageParam,
    }): Promise<{
      data: {
        currentPage: number;
        nextPage: number;
        results: {
          $values: Product[];
        };
      };
    }> => {
      const response = await sendRequest({
        method: "GET",
        url: `Products/homepage-random-products?page=${
          pageParam ?? 1
        }&pageSize=18`,
      });
      return await response.data;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 60 * 24,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      hasProducts ? lastPage.data.nextPage : null,
  };
};

export const loader = (queryClient) => async () => {
  const query = trendingProductsQuery();
  return await queryClient.fetchInfiniteQuery(query);
};
